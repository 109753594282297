import React from "react"
import styled from "styled-components"
import { device } from "../../../utils/mediaQueries"

const Header = styled.h4`
  padding: 0rem 16rem;
  color: black;
  font-weight: 800;
  color: #1616b5;
  font-size: 1.3rem;
  text-align: center;
  @media ${device.tablet} {
    padding: 0rem;
    margin-top: 1rem;
  }
`

const Header4 = props => {
  return <Header>{props.children}</Header>
}

export default Header4
