import React from "react"
import styled from "styled-components"
import { device } from "../../../utils/mediaQueries"

const Area = styled.div`
  margin: 3rem 6rem;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: white;
  @media ${device.tablet} {
    margin: 0rem 1rem;
    padding: 0rem;
    border-radius: 0px;
  }
`

const Container = props => {
  return <Area>{props.children}</Area>
}

export default Container
