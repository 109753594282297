import React from "react"
import styled from "styled-components"
import { device } from "../../../utils/mediaQueries"

const Header = styled.h3`
  font-size: 30px;
  font-weight: 700;
  color: #a35dbc;
  line-height: 1;
  margin-top: 1rem;
  padding: 0rem 16rem;

  @media ${device.tablet} {
    padding: 0.2rem;
    font-size: 20px;
    text-align: center;
  }
`

const Header3 = props => {
  return <Header>{props.children}</Header>
}

export default Header3
