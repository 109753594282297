import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql } from "gatsby"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import MetaDetails from "../components/seoComponents/MetaDetails"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import styled from "styled-components"
import { device } from "../utils/mediaQueries"

const ActionButton = styled.a`
  text-decoration: none;
  padding: 1rem 3rem;
  background-color: orangered;
  color: white;
  word-wrap: break-word;
  text-align: center;
  margin: 0px auto;
  @media ${device.tablet} {
    padding: 0.7rem;
  }
`

const FrontPage = () => {
  return (
    <GlobalStyles>
      <MetaDetails
        title="Tee suoramarkkinointikielto ja puhelinmyynnin esto netissä!"
        desc="Laita sinulle puhelinmyynti esto ja markkinointikielto puhelimeen. Vain yhdellä tekstiviestillä varmistat kaiken tämän ja pääset kauppiaista eroon."
      />
      <Container>
        <Header1>
          Tee suoramarkkinointikielto ja puhelinmyynnin esto netissä!
        </Header1>
        <ActionButton href="/tilaa/kielto" target="_blank">
          Tee suoramarkkinointikielto tästä!
        </ActionButton>
        <TextArea>
          Yleisesti ottaen eniten suomalaisia ärsyttävä asia on puhelinmyynti ja
          muu suoramarkkinointi. Mikä voisi olla turhauttavampaa, kun keskellä
          kaunista kesäpäivää kuunnella puhelimen kaiuttimista loputtomalta
          tuntuvaa myyntipuhetta tarpeettomista aikakausilehdistä, eikä "ei
          kiinnosta" tunnu tehoavan? Puhelinmyyjät tekevät osaltaan työtänsä,
          mutta vain harva vastaaja pystyy piilottelemaan kiukkuaan. Onneksi
          kaikenlaisen suoramyynnin kieltäminen on tänä päivänä jopa yllättävän
          helppoa ja ilmaista, vaikkakin medioissa pyörii monenlaista tietoa.
        </TextArea>
        <Header2>Pelkkä puhelinmyynnin esto ei riitä</Header2>
        <TextArea>
          Puhelinmyynti on kuitenkin vain yksi suoramarkkinoinnin muoto. Tänä
          päivänä henkilötietoja jopa myydään suoraan yrityksille, esimerkiksi
          Traficomin toimesta. Tutustumme artikkelissa lähemmin kaikkiin
          suoramarkkinoinnin tapoihin ja henkilötietojen myyntiin sekä kerromme
          keinot, joilla pystyt yksinkertaisesti kieltämään kaikki
          suoramarkkinointikeinot. Kielto on aina voimassa kolme vuotta. Jatka
          artikkelimme lukemista ja tee päätös piinaavalle puhelinmyynnille
          vaivattomasti.
        </TextArea>
        <Header3>Mitä suoramarkkinointi tarkoittaa?</Header3>
        <TextArea>
          Yksinkertaisesti, nimensäkin mukaisesti, suoramarkkinoinnilla
          tarkoitetaan markkinointitapaa, jolla pyritään tavoittelemaan
          kuluttajaa suoraan tai johon kuluttaja voi vastata suoraan. Se voi
          tapahtua sähköisesti sähköpostilla tai perinteisemmillä keinoilla,
          kuten postitse ja puhelimitse. Yleisin tunnettu tapa onkin juuri
          puhelinmyynti, joka yhdistetään helposti ainoaksi
          suoramarkkinointitavaksi. Yhtä lailla postiluukusta tupsahtavat
          nimettömät mainoslehtiset tai kuvastot sekä katalogit ovat suoraan
          kuluttajalle suunnattua markkinointia, jota kuitenkaan ei koeta yhtä
          häiritseväksi kuin puhelinmyyntiä. Monesti uusia palveluja ja
          tuotteita ostaessa voi jopa huomaamatta antaa luvan
          suoramarkkinointiin tai henkilötietojen käyttöön
          suoramarkkinointitarkoituksissa. Sen yleensä huomaakin vasta sitten,
          kun kotiin alkaa tupsahdella uusia mainoslehtisiä tai puhelin soi
          normaalia useammin.
        </TextArea>
        <Header4>Painettu suoramarkkinointi</Header4>
        <TextArea>
          Painettu suoramarkkinointi voi tarkoittaa kaikenlaisia postitettuja
          mainosmateriaaleja, kuten esimerkiksi kirjeitä, esitteitä, kuvastoja
          ja jopa postikortteja. Korttien käyttö perustuu niiden
          yksinkertaisuuteen, eikä niitä heitetä yhtä helposti roskakoriin kuin
          esimerkiksi vaatekaupan kuvastoa. Kuluttajalle postista tippuneiden
          kuponkien, lehtisten ja kuvastojen selaaminen on aikaa vievää, ja
          monesti siinä herääkin kysymyksiä siitä, että miksi juuri minulle on
          niitä lähetetty? Painettu suoramarkkinointi onkin siirtymässä
          hiljalleen kohti sähköisiä keinoja, joita pidetään moderneina sekä
          lisäksi luontoystävällisempinä. Tosin yhä edelleen jotkut painetut
          kuvastot, kuten jouluiset lelukuvastot ja IKEA:n vuosittainen
          katalogi, ovat jopa tervetulleita ja haluttuja moneen kotiin johtuen
          niihin liittyvistä traditioista
        </TextArea>
        <Header4>Suoramarkkinointikielto netissä</Header4>
        <ActionButton href="/tilaa/kielto" target="_blank">
          Tee suoramarkkinointikielto tästä!
        </ActionButton>
        <TextArea>
          Nykyaikaisempi tapa ottaa suoraan yhteyttä kuluttajaan on sähköisesti
          joko sähköpostitse tai tekstiviestillä. Tästä huolimatta kaikki
          sähköiset yhteydenotot eivät ole suoramarkkinointia. Monet suuret
          sanomalehdet lähettävät sähköisesti tietoa jakeluhäiriöistä tai
          palvelukatkoksista. Yhtä lailla yritysten lähettämiä uutiskirjeitä ei
          luetella suoramarkkinoinniksi. Suoramarkkinointitarkoituksissa
          lähetettyjä viestejä personoidaan kuluttajalle sopiviksi esimerkiksi
          lähiaikojen tilausten tai palvelujen käytöstä kerättyjen tietojen
          avulla. Sähköinen suoramarkkinointi on nykyajan teknologian avulla
          erittäin helppoa toteuttaa ja jopa automatisoitua. Sitä voidaan myös
          käyttää monella eri kanavalla samanaikaisesti, aiheuttamatta
          lisäkuluja yritykselle. Yhtä lailla sen tuloksia voi helpommin mitata
          ja analysoida. Sähköinen suoramarkkinointi eroaa kuitenkin painetusta
          markkinoinnista seuraavasti:
          <ul>
            <li>
              Painettua suoramarkkinointia voi lähettää kuluttajalle niin kauan
              kunnes hän kieltää sen.
            </li>
            <li>
              Sähköistä suoramarkkinointia voidaan tehdä vain silloin, kun
              kuluttaja on antanut siihen suostumuksensa.
            </li>
            <li>Suostumus annetaan yleensä esimerkiksi palvelua ostaessa</li>
            <li>
              Yritykset voivat kuitenkin lähettää sähköistä suoramarkkinointia
              jo voimassa oleville asiakkailleen.
            </li>
          </ul>
        </TextArea>
        <Header4>Nettimarkkinointi</Header4>
        <TextArea>
          Sähköisten markkinointikanavien, kuten Facebookin, avulla yritykset
          pystyvät nostamaan markkinointinsa kohdentamisen ja segmentoinnin
          aivan uudelle tasolle. Ennen vanhaa mainokset esiintyivät
          verkkosivuilla häiritsevinä pop up -ikkunoina tai välkkyvinä
          paneeleina, jotka ovat nykyään häviämään päin. Sähköisiä tapoja
          markkinoida ovat tekstiviestien ja sähköpostien lisäksi myös
          hakukonemainonta, verkkosivut ja sosiaalinen media, etenkin
          aikaisemmin mainittu Facebook. Yritysten määrän kasvaessa ja
          markkinoiden globaalisuuden aallossa kuluttajat reagoivat yhä
          nihkeämmin tylsältä näyttäviin mainoksiin, mikä on tuonut omat
          haasteensa markkinoijille. Erottuminen massasta on todella vaikeaa.
          Digitaalisen median ansiosta myös tulosten analysointi ja
          markkinointikeinojen muokkaaminen on aikaisempaa nopeampaa ja
          helpompaa.
        </TextArea>
        <Header3>Puhelinmyynti</Header3>
        <TextArea>
          Puhelinmyynti on täysin oma suoramarkkinoinnin keino, joka mielletään
          myös etämyynniksi. Tällöin tuotteita pyritään kauppaamaan suoraan
          kuluttajalle puhelimitse. Telemarkkinoinniksikin kutsuttu
          puhelinmyynti kasvoi etenkin 1990-luvun aikana, jatkuen edelleen tähän
          päivään asti. Monesti nuoret myyntineuvottelijat löytävät asiakkaansa
          tietokoneavusteisesti. Tietokoneohjelma soittaa jatkuvasti
          rekisterissä löytyviin numeroihin, ja kuluttajan vastatessa puhelu
          yhdistyy suoraan vapaana olevalle myyntineuvottelijalle. Toinen tapa
          on se, että myyntineuvottelija itse valitsee tietokoneelta asiakkaansa
          esimerkiksi iän tai ostohistorian perusteella. Myynti voi tapahtua
          joko sillä tavalla, että asiakkaalle soitetaan tai että asiakas
          soittaa itse. Puhelinmyynti on yhä useammin ulkoistettu asiaan
          erikoistuneille puhelinmyyntiyrityksille, jotka voivat hoitaa samaan
          aikaan useamman kuin yhden yrityksen myynnin.
        </TextArea>
        <Header4>Kuluttajansuojalaki</Header4>
        <TextArea>
          Kuluttajansuojalakiin on määritelty puhelinmyyntiä varten
          erikoiskohtia. Lain mukaan myyntineuvottelijan on heti puhelun
          alkaessa ilmoitettava, että kyseessä on myyntipuhelu. Kuluttajalla on
          myös oikeus perua kauppa 14 päivän sisällä tuotteen saapumisesta.
          Lisäksi puhelimitse tehdystä kaupasta on lähettävä kuluttajalle
          kirjallinen ilmoitus, jonka pitää myös sisältää tiedot kauppojen
          perumismahdollisuudesta ja -ehdoista. Suomalaisia tunnettuja
          puhelinmyyntiyrityksiä ovat muun muassa Buusteri, Directa, Finnacta ja
          Novia Finland. Osa yrityksistä toimii Suomessa, osa ulkomailla, jossa
          toiminnan kiinteät kulut ovat halvempia kuin muualla ja suomalaisia
          työntekijöitä on tarjolla, esimerkiksi Espanjassa. Kuluttajalaki
          vaikuttaa kuitenkin kumpaankin toimintamalliin, eikä kuluttajalle ole
          eroa siitä, soitetaanko hänelle Etelä-Euroopasta vai Keski-Suomesta.
        </TextArea>
        <Header4>Ärsyttävä puhelinmyynti</Header4>
        <TextArea>
          Puhelinmyynti on erittäin kiistanalainen suoramarkkinointikeino. Se on
          saanut kritiikkiä monesti siitä, ettei se välttämättä noudata
          kuluttajan asettamaa suoramarkkinointikieltoa. Yhtenä haittapuolena
          pidetään myös sitä, että häiritseväksi koettujen myyntipuheluiden
          takia ihmiset vastaavat yhä harvemmin tuntemattomista numeroista
          saapuviin puheluihin. Sillä on taasen suora vaikutus esimerkiksi
          viranomaisten, Kelan ja Postin toimintaan, jotka eivät saa
          tarvittaessa yhteyttä asiakkaaseen. Haittapuolia ovat myös lasten
          saamat puhelut, jotka eivät kuitenkaan ole tahallisia, vaan yleensä
          puhelinliittymien omistajiksi on merkitty lapsen vanhemmat. Se voidaan
          kuitenkin välttää muuttamalla lasten numerot salaisiksi, ja neuvomalla
          lapsia olla kirjoittamatta puhelinnumeroaan arvonta- tai
          kilpailulomakkeisiin.
        </TextArea>
        <Header4>Kuka tykkää puhelinmyyjistä?</Header4>
        <TextArea>
          Tutkimuksen mukaan se on myös vähiten pidetyin suoramarkkinointikeino:
          jopa 61 % vastaajista ei halua vastaanottaa myyntipuheluita ja vain
          viiden prosentin mielestä se on mieluista. Puhelinmyyntiyritykset ovat
          myös saaneet kritiikkiä työntekijöiden huonosta kohtelusta ja huonosta
          palkkatasosta, joka sisältää yleensä takuupalkan ja provision
          myydyistä tuotteista. Yleisesti ottaen puhelinmyyntityötä arvostetaan
          vähiten kaikista ammateista. Haastava työympäristö ja negatiivinen
          asiakaskunta ei ainakaan houkuttele työn pariin, mutta monelle
          nuorelle se on ensikosketus työelämään. Harvemmin kuitenkaan
          myyntipuhelun saapuessa tulee mietittyä soittavan myyntineuvottelijan
          työoloja, vaan helpoin tapa väistää epämukavat puhelut on asettaa
          puhelinmyynnille eston, jota käsittelemme artikkelin seuraavassa
          kohdassa.
        </TextArea>
        <Header3>Kuinka puhelinmyynnin voi estää?</Header3>
        <TextArea>
          Suoramarkkinointi on yleisesti sallittua, mutta kuluttajalla on oltava
          mahdollisuus kieltää se ilman ylimääräisiä kuluja. Kielto asetetaan
          jokaiselle markkinointitavalle erikseen. Lain mukaan alle
          15-vuotiaalle ei saa lähettää suoramarkkinointimateriaalia ilman
          vanhemman tai huoltajan suostumusta. Hyvän maun ja lain mukainen
          suoramarkkinointi ei ole aggressiivista tai häiritsevää, eikä se saa
          sisältää pakottamista tai muuta kuluttajan painostamista, jonka
          tarkoitus on saada kuluttaja tekemään ostopäätös, jota hän ei tekisi
          ilman painostusta. Suoramarkkinointikiellosta voi ilmoittaa suoraan
          yritykselle, kieltämällä kuluttajan henkilötietojen luovutuksen
          viranomaisten ylläpitämistä rekistereistä tai ilmoittautumalla
          suoramarkkinoinnin rajoituspalveluun. Asiakkuusmarkkinointiliitto
          (ASML) pitää hallussaan osoitteellisen suoramainonnan ja
          puhelinmyynnin kieltopalvelua, joka on toiselta nimeltään
          Robinson-rekisteri.{" "}
          <a href="https://www.asml.fi/kieltopalvelut/">
            https://www.asml.fi/kieltopalvelut/
          </a>
        </TextArea>
        <Header4>Suoramarkkinointikielto Robinson rekisteriin</Header4>
        <TextArea>
          Kuluttaja voi ilmoittautua rekisteriin soittamalla ASML:n
          palvelupuhelimeen arkisin kello 8-18 välillä. Rajoitus annetaan
          puhelinpalvelussa puhetunnistuksen avulla, jossa kuluttajan täytyy
          vastata kyllä yhteen kieltokysymykseen. Rajoitus hoituu yleensä alle
          minuutissa, minkä jälkeen se on voimassa kolmen vuoden ajan. Hintaa
          puhelulla on 0,39 €/minuutti plus paikallisverkkomaksu, jonottaminen
          on maksutonta. Puhelun jälkeen kuluttajan puhelinnumero liitetään
          rekisteriin välittömästi, ja se välittyy viikon sisällä
          puhelinmyyntiyrityksille. Viimeistään kuukauden sisällä se on alkaa
          toimia täydellisesti. Robinson-rekisteriin on liittynyt yli 600
          puhelinmyyntiyritystä, jotka ovat sitoutuneet noudattamaan kuluttajan
          pyytämää rajoitusta. Rekisteri on positiivinen asia myös yrityksille:
          he säästävät aikaa ja vaivaa soittaessaan sellaisille henkilöille,
          jotka haluavat ostaa jotain.
        </TextArea>
        <Header4>Eri rekisterit suoramarkkinointikieltoon</Header4>
        <ActionButton href="/tilaa/kielto" target="_blank">
          Tee suoramarkkinointikielto tästä!
        </ActionButton>
        <TextArea>
          Asiakkuusmarkkinointiliiton ylläpitämiä rekistereitä on kaksi:
          Puhelin-Robinson ja Posti-Robinson, joihin kumpaankin täytyy
          ilmoittautua erikseen. Tänä päivänä vain vajaat 300 000 suomalaista on
          ilmoittautunut Robinson-rekisteriin, mikä on yllättävän vähäinen
          määrä. Asiantuntijoiden mukaan se johtuu siitä, että kuluttajat eivät
          ole tietoisia tästä kieltomuodosta. Kieltorekisteriin ei myöskään
          kuulu kaikki Suomen puhelinmyyntiyritykset, minkä takia se voi monesta
          tuntua turhalta – puheluita voi tulla siis edelleenkin. Rekisterin
          rinnalla puhelinmyyntiä voi rajoittaa myös ilmoittamalla sen suoraan
          yritykselle, eli puhelinmyyjälle. Melkeinpä aina myyntipuhelu tulee
          täysin yllättävissä tilanteissa, eikä kuluttaja pysty mitenkään siihen
          varautumaan tai hankkimaan taustatietoa tuotteesta. Tällöin turhan
          ostoksen sijaan on parasta ilmoittaa myyjälle suoraan soittokiellosta.
        </TextArea>
        <Header4>Suomen telemarkkinointiliitto ry</Header4>
        <TextArea>
          Puhelinmyynti voidaan myös helposti saada loppumaan tekemällä
          markkinointikielto{" "}
          <a href="/tilaa/kielto" target="_blank">
            Suomen telemarkkinointiliitto ry:hyn.
          </a>{" "}
          Tällöin kuluttajan täytyy lähettää tekstiviestillä sana ESTO numeroon
          173322, minkä jälkeen hän on samalla liiton kannattajajäsen ja hyötyy
          heidän laajasta puhelinmyyntikieltopalvelusta. Palvelusta peritään
          kuuden euron vuosimaksu, joka sisältää telemarkkinointikiellon,
          reklamoinnin kuluttajan puolesta lakia rikkoville yrityksille,
          mahdollisten toimenpideilmoitusten teon tietosuojavaltuutetuille sekä
          suoramarkkinointikiellon välittämisen Traficomille ja VRK:lle.
          Jäsenmaksu maksetaan verkkopankkitunnuksilla. Jäsenyys uusitaan
          automaattisesti joka vuosi, jollei sitä erikseen irtisanota. Suomen
          telemarkkinointiliitto ry ilmoittaa erikseen kaikille Suomessa
          toimiville yrityksille jäsentensä suoramarkkinointikiellosta.
          Rekisteriin liitetään jatkuvasti uusia suoramarkkinointiyrityksiä,
          joita myös jäsenet voivat ilmoittaa liitolle.
        </TextArea>
        <Header3>Muiden suoramarkkinointikeinojen kieltäminen</Header3>
        <TextArea>
          Muita suoramarkkinointikeinoja ovat osoitteeton ja osoitteellinen sekä
          sähköinen suoramarkkinointi. Niiden estäminen vaatii hieman erilaisen
          käytännön kuin aikaisemmin mainitsemassamme puhelinmyynnissä.
          Osoitteettomaksi suoramyynniksi lasketaan postiluukusta tipahtaneet,
          nimettömät mainoslehtiset ja kuvastot. Niiden estäminen on kaikkein
          helpointa. Riittää että kirjoittaa pienellä lapulla asunnon
          ulkopuolelle – yleensä postiluukun yläpuolelle – ”ei mainoksia”- tai
          ”ei ilmaisjakelua” -tekstin, jolloin mainosten jakajat tietävät olla
          jakamatta materiaalia kuluttajalle. Tätä rajoitusta ei voi rajata vain
          tiettyyn mainostyyppiin, vaan se koskee kaikkea osoitteetonta
          suoramarkkinointia. Ainoana poikkeuksena ovat viralliset tiedotteet ja
          sanomalehtien väliset mainosliitteet, joiden jakamista ei voida lain
          mukaan tällä tavoin kieltää.
        </TextArea>
        <Header4>Ei suoramarkkinointia ilman lupaa</Header4>
        <TextArea>
          Sähköistä suoramarkkinointia varten täytyy kuitenkin olla kuluttajan
          suostumus etukäteen. Jos suostumusta ei ole annettu,
          markkinointiviestejä lähettänyt yritys rikkoo lakia. Suostumus
          annetaan yleensä uuden palvelun yhteydessä, ja se on lain mukaan
          oltava selkeästi rastitettavissa oleva, muusta sisällöstä eroava
          valintaruutu. Valintaruutu ei missään nimessä saa olla etukäteen
          täytetty markkinoinnin tai sopimuksen tekemisen yhteydessä.
          Tietosuojavaltuutettu valvoo palveluja koskevan lain säännösten
          noudattamista. Kuluttajansuojalain noudattamista valvoo taas
          kuluttaja-asiamies, joka suojelee kuluttajaa erityisesti häiritsevältä
          ja aggressiiviselta suoramarkkinoinnilta. Poikkeuksena sähköiselle
          suoramarkkinoinnille on voimassa olevat asiakkaat, joille yritys voi
          lähettää tietoa ostohistorian perusteella valituista, samanlaisista
          tuotteista ja palveluista ilman kuluttajan ennakkosuostumusta
          noudattaen seuraavia käytäntöjä:
          <ul>
            <li>Henkilötiedot on saatu asiakkuuden luomisen yhteydessä.</li>
            <li>
              Jo voimassa oleville asiakkaille voidaan lähettää tietoa samalla
              tavalla kuin myynti on tehty, esimerkiksi tekstiviestillä.
            </li>
            <li>
              Kaupan yhteydessä kuluttajaa on informoitava
              markkinointiviesteistä.
            </li>
            <li>
              Viesteihin pitää tästäkin huolimatta lisätä tieto
              kieltomahdollisuudesta.
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Myös yritysten yhteyshenkilöille voi lähettää sähköistä
          suoramarkkinointimateriaalia niin kauan, kunnes hän sen kieltää.
          Kuluttaja voi estää sähköisen suoramarkkinoinnin, kuten tekstiviestien
          ja sähköpostien lähettämisen, perumalla siihen antamansa suostumuksen
          tai ilmoittamalla estosta suoraan mainostavalle yritykselle. Lain
          mukaan yrityksen täytyy huomioida ja ottaa kielto toimintaan
          välittömästi. Jotkut yritykset ovat lisänneet sähköposteihinsa suoran
          linkin, josta voi perua mainosten tulon. Suostumus
          tekstiviestimainontaan on voitu myös antaa esimerkiksi
          puhelinliittymää ostaessa, joten puhelinsopimuksen tarkistaminen on
          hyvä paikka aloittaa. Jokaisessa myyntitilanteessa tai
          suoramarkkinoinnissa asiakkaalla on oikeus tietää, mistä yritys on
          saanut hänen henkilötietonsa, jolloin hän voi asettaa suoraan
          henkilötietojen jakamiskiellon kyseiselle taholle. Kerromme tästä
          lisää myöhemmin.
        </TextArea>
        <Header4>Suoramarkkinointia sähköpostilla</Header4>
        <TextArea>
          Tänä päivänä matkapuhelin- ja laajakaistaliittymiä myydään sillä
          ehdolla, että asiakas hyväksyy mainosviestien vastaanottamisen joko
          sähköpostitse, teksti- tai multimediaviesteinä. Mainosviestejä
          tyrkyttävät liittymätyypit ovat lain mukaan sallittuja, mutta
          kuluttajalle pitää myös tarjota liittymä ilman velvollisuutta
          vastaanottaa mainoksia. Liittymäsopimusten aikana kuluttajan pitää
          saada selkeästi tietoa siitä minkälaista ja millä tiheydellä hänelle
          tullaan mainosviestejä lähettämään. Kuluttajalla on myös mahdollisuus
          perua suostumuksensa kulutta. Yhtä lailla tilaamattomista tuotteista
          tai näytteistä ei voida vaatia maksua, palauttamista, säilyttämistä
          tai muutakaan toimenpidettä. Niin sanottu negatiivinen myyntitapa on
          kuluttajansuojalaissa kiellettyä, joten kuluttaja voi täten pitää
          kaikki tilaamattomat tuotteet ilman seuraamuksia.
        </TextArea>
        <Header3>Henkilötietojen myymisen estäminen</Header3>
        <TextArea>
          Monelle voi tulla yllätyksenä, että osoitetietojen myyminen on monelle
          taholle kannattavaa bisnestä. Väestörekisterikeskus myy osoitetietoja
          vuosittain monille yrityksille aina matkatoimistoista pankkeihin ja
          urheiluseuroista lehtitaloihin. Kyseinen toiminta kasvatti
          Väestörekisterikeskuksen kassaa vuonna 2013 jopa 1,5 miljoonalla
          eurolla – ja myyntitoimet jatkuvat edelleen. Tänä päivänä melkeinpä
          kaikki puhelinmyyntiä harjoittavat yritykset saavat tietonsa juuri
          Väestörekisterikeskuksesta. Toinen osoitetietoja myyvä taho on
          Liikenne- ja viestintävirasto, eli Traficom. Ajoneuvoliikennerekisteri
          on yleisesti ottaen julkinen rekisteri, mutta kuluttaja voi
          halutessaan rajoittaa myös omien tietojensa luovutusta. Ostettuja
          osoitetietoja käytetään osoitteellisen suoramarkkinointimateriaalin
          lähettämiseen suoraan kuluttajalle. Markkinointia voi rajoittaa
          tässäkin tapauksessa ilmoittamalla suoraan myyjälle tai
          markkinoijalle.
        </TextArea>
        <Header4>Kiellot rekisteriin</Header4>
        <ActionButton href="/tilaa/kielto" target="_blank">
          Tee suoramarkkinointikielto tästä!
        </ActionButton>
        <TextArea>
          Helpoin tapa on kuitenkin kieltää suoraan nimi- ja osoitetietojen
          luovutus viranomaisten ylläpitämistä tietojärjestelmistä. Jokainen voi
          estää tietojensa luovuttamisen väestörekisteristä suoramarkkinointi-,
          mielipide- tai tutkimustarkoituksiin tai suku- sekä
          henkilöhistoriatutkimuksiin. Kielto koskee myös nimi- ja
          osoitetietojen luovuttamista niitä vastaavana tietopalveluna tai
          asiakasrekistereihin lisäämisen esimerkiksi päivitysten yhteydessä.
          Niin ikään suoramarkkinoinnin rajoituspalvelut, kuten ASML:n
          Posti-Robinson ja Suomen telemarkkinointiliitto ry:n kieltopalvelut
          ajavat asiaa. Väestörekisterikeskukseen (VRK) voi toimittaa
          kieltopyynnön verkkopankkitunnusten tai henkilökortin avulla VRK:n
          sivuilta löytyvästä Tarkista tietosi -palvelusta. Kiellon voi yhtä
          lailla ilmoittaa puhelimitse rekisterikeskukseen tai kirjoittamalla
          vapaamuotoisen kirjeen ja toimittamalla sen oman kaupunkinsa
          maistraattiin.
        </TextArea>
        <Header4>Kiellot lomakkeella</Header4>
        <TextArea>
          Netistä löytyy myös PDF-muotoisena tulostettava Henkilötietojen
          luovutuksen kieltäminen -lomake, joka viedään täyttämisen jälkeen
          lähimpään maistraattiin. Puhelinmarkkinoinnin estoa ei voi tehdä
          väestörekisteriin, sillä se ei tallenna puhelinnumeroita. Traficomille
          tehtävä tietojenluovutuskielto täytyy tehdä suoraan virastolle
          sähköisesti sen nettisivulta Omat tiedot -palvelun kautta tai
          soittamalla virka-aikoina viraston palvelupuhelimeen. Kuluttaja voi
          valita haluaako kieltää tietojensa luovuttamisen pelkästään
          suoramarkkinointiin, vain myös muihin tarkoituksiin, muun muassa
          kehitys- ja innovaatiotyöhön, viranomaisille ja muille virkatehtäviä
          hoitaville tai tietojen jakamisen tekstiviestipalvelun kautta muille
          käyttäjille esimerkiksi ajoneuvotietoja tarkastaessa. Ajoneuvon tiedot
          ja omistajan nimi ovat kuitenkin julkista tietoa, jota ei voida
          suodattaa pois palvelusta.
        </TextArea>
        <Header4>Varo huijauksia</Header4>
        <TextArea>
          Monen muunkin alan tavoin, myös suoramarkkinointia tai puhelinmyyntiä
          käytetään huijausten tekemiseen. Puhelimitse voidaan yrittää myydä
          erilaisia ihmetuotteita, joiden toimivuudesta ei ole lääketieteellistä
          näyttöä. Sähköpostitse voidaan esimerkiksi lähettää mainoksia
          Viagrasta tai muista reseptilääkkeistä. Puhelinmyynnin tuloksena
          asiakkaalle voi yhtä lailla ilmestyä valelaskuja, joissa pyydetään
          välitöntä maksua, vaikka puhelimessa on puhuttu toista. Kuluttajan on
          oltava tietoinen oikeuksistaan, ja ottaa tarvittaessa yhteyttä
          kuluttajaviranomaiseen tai poliisiin. Tuotteita ei tulisi koskaan
          tilata yrityksiltä, joiden kampanjat ja tiedot ovat epäilyttävän
          oloiset. Yhtä lailla puhelinmyyntiin on hyvä suhtautua rauhallisesti,
          eikä ainakaan suostua välittömästä jopa liian hyvältä kuulostaviin
          tarjouksiin – harvemmin ne niitä ovat.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Suoramarkkinointi on monipuolinen ala, joka koostuu monesta
          erilaisesta tavasta. Yleisesti ottaen kuluttaja mieltää puhelinmyynnin
          ainoaksi suoramyynnin tavaksi, mutta yhtä lailla jokaisen
          postiluukusta tipahtelevat mainoslehtiset, kuvastot ja katalogit
          kuuluvat sen piiriin. Tänä päivänä perinteisen postimainonnan vierelle
          on myös ilmestynyt sähköinen suoramarkkinointi, joka on yrityksille
          tehokas ja edullinen markkinointikeino. Sähköpostien tai
          tekstiviestien lähettäminen ei olekaan yhtä häiritsevää kuin
          puhelinsoitot tai ilmaismainonta.
        </TextArea>
        <Header3>Puhelinmyyntikielto on helppo tehdä</Header3>
        <TextArea>
          Puhelinmyynnin voi estää parhaiten liittymällä
          Asiakkuusmarkkinointiliiton Robinson-listalle tai liittymällä Suomen
          telemarkkinointiliitto ry:n kannatusjäseneksi. Kyseiset tahot voivat
          kieltää myös postimyynnin, joka perustuu nimi- ja osoitetietojen
          ostamiseen Väestörekisterikeskuksesta ja Traficomista. Muussa
          tapauksessa kuluttaja voi tehdä kumpaankin virastoon itse ilmoituksen
          nimi- ja osoitetietojensa luovutuskiellosta sähköisesti tai
          puhelimitse. Lakia rikkovat yritykset on hyvä ilmiantaa
          tietosuojavaltuutetulle.
        </TextArea>
        <ActionButton href="/tilaa/kielto" target="_blank">
          Tee suoramarkkinointikielto tästä!
        </ActionButton>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson(filter: { internetType: { in: "2g" } }) {
      nodes {
        id
        benefits
        callPrice
        internet
        internetSpeed
        name
        price
        type
        url
        textPrice
        img
        openFee
      }
    }
  }
`

export default FrontPage
