import React from "react"
import styled from "styled-components"
import { device } from "../../../utils/mediaQueries"

const Header = styled.h1`
  font-size: 3rem;
  text-align: center;
  font-weight: 800;
  word-wrap: break-word;
  padding-top: 3rem;
  color: ${props => props.color || "#3a3a3a"};
  margin: 0rem;
  margin-left: ${props => props.blog || "0rem"};
  padding: 2rem 16rem;
  @media ${device.tablet} {
    padding: 0rem;
    padding-top: 3rem;
    margin-left: 0rem;
    font-size: 1.5rem;
    font-weight: 900;
  }
`

const Header1 = props => {
  return (
    <Header color={props.color} blog={props.blog}>
      {props.children}
    </Header>
  )
}

export default Header1
