import React from "react"
import styled from "styled-components"
import { device } from "../../../utils/mediaQueries"

const Header = styled.h2`
  font-size: 45px;
  font-weight: 900;
  color: black;
  margin-top: 1rem;
  line-height: 1;
  padding: 0rem 16rem;
  @media ${device.tablet} {
    padding: 0.2rem;
  }

  @media ${device.tablet} {
    font-size: 22px;
  }
`

const Header2 = props => {
  return <Header>{props.children}</Header>
}

export default Header2
