import React from "react"
import styled from "styled-components"
import { device } from "../../../utils/mediaQueries"

const Text = styled.p`
  padding: 0rem 16rem;
  background-color: white;
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  @media ${device.tablet} {
    padding: 0.2rem;
    text-align: left;
  }
`

const TextArea = props => {
  return <Text>{props.children}</Text>
}

export default TextArea
